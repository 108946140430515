import { DirectiveBinding } from 'vue';

const positiveNumberDirective = {
    mounted(el: HTMLInputElement, binding: DirectiveBinding) {
        const min = 0; // Minimum value is already enforced
        const max = binding.value?.max !== undefined ? binding.value.max : Infinity; // Use Infinity if no max is provided

        el.addEventListener('input', () => {
            let value = el.value;

            // convert value to Number 
            let numberValue = Number(value);

            // If the value is not a number, set it to the minimum
            if (isNaN(numberValue)) {
                numberValue = min;
            }

            // If the value is less than the minimum, set it to the minimum
            if (numberValue < min) {
                numberValue = min;
            }

            // If the value is greater than the maximum, set it to the maximum
            if (numberValue > max) {
                numberValue = max;
            }

            // If the value has changed, update the input
            if (value !== numberValue.toString()) {
                el.value = numberValue.toString();
            }

            el.dispatchEvent(new Event('input')); // Trigger the input event to update the model
        });
    }
};

export default positiveNumberDirective;
