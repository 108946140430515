import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "min-w-full divide-y divide-gray-300 bg-white ring-1 ring-gray-300 rounded-md"
}
const _hoisted_2 = { class: "bg-gray-50" }
const _hoisted_3 = {
  key: 0,
  class: "px-8 py-4 text-start text-sm font-semibold text-gray-500"
}
const _hoisted_4 = { class: "divide-y divide-gray-200" }
const _hoisted_5 = {
  key: 0,
  class: "flex gap-x-2 px-6 py-4 whitespace-nowrap text-start text-sm font-medium"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 1,
  class: "mt-4 text-sm text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonConfirmation = _resolveComponent("ButtonConfirmation")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.items.length)
      ? (_openBlock(), _createElementBlock("table", _hoisted_1, [
          _createElementVNode("thead", _hoisted_2, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
                return (_openBlock(), _createElementBlock("th", {
                  key: field,
                  class: "px-6 py-3 text-start text-sm font-semibold text-gray-500"
                }, [
                  (!field.includes('_id'))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t(`store.${field}`)), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ]))
              }), 128)),
              (_ctx.canEdit || _ctx.canDelete)
                ? (_openBlock(), _createElementBlock("th", _hoisted_3, _toDisplayString(_ctx.$t("store.actions")), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("tbody", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: item.id
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: field,
                    class: "px-6 py-4 text-sm text-gray-500 max-w-72"
                  }, [
                    (_ctx.modelFields[field] === 'object')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(item[field]?.name || "-"), 1)
                        ], 64))
                      : (!field.includes('_id'))
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(item[field]), 1)
                          ], 64))
                        : _createCommentVNode("", true)
                  ]))
                }), 128)),
                (_ctx.canEdit || _ctx.canDelete)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_5, [
                      (_ctx.canEdit)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            onClick: ($event: any) => (_ctx.$emit('edit', item)),
                            class: "text-indigo-600 hover:text-indigo-900 mr-2"
                          }, _toDisplayString(_ctx.$t("store.edit")), 9, _hoisted_6))
                        : _createCommentVNode("", true),
                      (_ctx.canDelete)
                        ? (_openBlock(), _createBlock(_component_ButtonConfirmation, {
                            key: 1,
                            onConfirm: ($event: any) => (_ctx.$emit('delete', item)),
                            class: "hover:bg-indigo-100 p-1 rounded-md"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("store.delete")), 1)
                            ]),
                            _: 2
                          }, 1032, ["onConfirm"]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t("store.no_data_available")), 1))
  ]))
}