import { DirectiveBinding } from 'vue';
import store from '@/store';

const canDirective = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        const permissions = store.getters['currentUser/currentUser']?.permissions.map((p: any) => p.permission);
        const requiredPermission = binding.value;

        if (!permissions || !permissions.includes(requiredPermission)) {
            el.parentNode?.removeChild(el);  // Remove the element from the DOM
        }
    }
};

export default canDirective;
