<template>
  <div class="max-w-72">
    <img v-if="!uploadedMediaUrl && mediaUrl" :src="mediaUrl" class="h-64 w-full min-w-64 object-cover mb-4" />
    <img v-if="uploadedMediaUrl" :src="uploadedMediaUrl" class="h-64 w-full min-w-64 object-cover mb-4" />
    <img v-if="previewUrl" :src="previewUrl" class="h-64 w-full min-w-64 object-cover mb-4" />

    <div v-if="!selectedFile" class="border-2 border-dashed border-gray-300 rounded-md p-5 text-center cursor-pointer transition-colors duration-300 min-w-64" :class="{ 'bg-gray-100': isDragging }" @dragover.prevent @drop.prevent="handleDrop" @dragenter.prevent="isDragging = true" @dragleave.prevent="handleDragLeave">
      <div class="flex flex-col items-center p-8">
        <i class="mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="17 8 12 3 7 8"></polyline>
            <line x1="12" y1="3" x2="12" y2="15"></line>
          </svg>
        </i>
        <p>{{ $t("common.drag_and_drop") }}</p>
        <p>{{ $t("common.or") }}</p>
        <label for="file-input" class="bg-transparent font-bold cursor-pointer hover:bg-gray-100 rounded-xl p-1">{{ $t("common.browse") }}</label>
        <input id="file-input" type="file" @change="handleFileSelection" accept="image/*" class="hidden" />
      </div>
    </div>

    <a @click.prevent="uploadMedia" :class="{ 'opacity-50 cursor-not-allowed': !selectedFile || !mediaLabel, 'cursor-pointer': selectedFile && mediaLabel }" class="block w-full mt-3 py-2 px-4 bg-indigo-500 text-white text-center rounded-md">
      {{ $t("common.upload") }}
    </a>

    <a @click.prevent="removeFile" v-show="selectedFile && mediaLabel" :class="{ 'opacity-50 cursor-not-allowed': !selectedFile || !mediaLabel, 'cursor-pointer': selectedFile && mediaLabel }" class="block w-full mt-3 py-2 px-4 bg-red-600 text-white text-center rounded-md">
      {{ $t("common.remove") }}
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MediaService from "@/services/mediaService";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "MediaUploader",
  props: {
    mediaLabel: {
      type: String,
      required: true,
    },
    mediaUrl: {
      type: String,
      default: "",
    },
  },
  data(props) {
    return {
      selectedFile: null as File | null,
      mediaLabel: props.mediaLabel,
      uploadedMediaUrl: "",
      isDragging: false,
      previewUrl: "",
      mediaUrl: props.mediaUrl,
      toast: useToast(),
    };
  },
  methods: {
    handleFileSelection(event: Event) {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        this.setSelectedFile(target.files[0]);
      }
    },
    handleDrop(event: DragEvent) {
      this.isDragging = false;
      if (event.dataTransfer?.files.length) {
        this.setSelectedFile(event.dataTransfer.files[0]);
      }
    },
    handleDragLeave(event: DragEvent) {
      if (!event.relatedTarget || !(event.currentTarget as Node).contains(event.relatedTarget as Node)) {
        this.isDragging = false;
      }
    },
    setSelectedFile(file: File) {
      this.selectedFile = file;
      this.previewUrl = URL.createObjectURL(file);
    },
    removeFile() {
      this.selectedFile = null;
      this.previewUrl = "";
    },
    async uploadMedia() {
      if (!this.selectedFile || !this.mediaLabel) return;

      try {
        const result = await MediaService.uploadMedia(this.selectedFile, this.mediaLabel);

        if (result.status == true && result.data) {
          this.uploadedMediaUrl = result.data.preview_url;
          this.toast.success(result.message);
          this.removeFile();
          this.$emit("media-uploaded", result.data);
        } else {
          this.toast.error(result.message);
        }
      } catch (error) {
        console.error("Upload error:", error);
        this.toast.error("An error occurred during upload");
      }
    },
  },
});
</script>
