import { App } from 'vue';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import LanguageSwitcher from './components/common/LanguageSwitcher.vue';
import ButtonConfirmation from './components/common/ButtonConfirmation.vue';
import ConfirmationDialog from './components/common/ConfirmationDialog.vue';
import Spinner from './components/common/Spinner.vue';
import MessageLayout from './components/common/MessageLayout.vue';
import MediaUploader from './components/common/MediaUploader.vue';
import AudioUploader from './components/common/AudioUploader.vue';
import AudioPlayer from './components/common/AudioPlayer.vue';
import AudioRecorder from './components/common/AudioRecorder.vue';
import PlatformSelector from './components/common/PlatformSelector.vue';
import GenericCrudComponent from "@/components/common/GenericCrudComponent.vue";
import MultiSelectDropdown from "@/components/common/MultiSelectDropdown.vue";
import FilesUploader from '@/components/common/FilesUploader.vue';

import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// Import the i18n instance
import { i18n } from './i18n';

export default {
  install(app: App) {
    app.component('Menu', Menu);
    app.component('MenuButton', MenuButton);
    app.component('MenuItems', MenuItems);
    app.component('MenuItem', MenuItem);
    app.component('LanguageSwitcher', LanguageSwitcher);
    app.component('ButtonConfirmation', ButtonConfirmation);
    app.component('ConfirmationDialog', ConfirmationDialog);
    app.component('Spinner', Spinner);
    app.component('MessageLayout', MessageLayout);
    app.component('MediaUploader', MediaUploader);
    app.component('AudioUploader', AudioUploader);
    app.component('AudioRecorder', AudioRecorder);
    app.component('AudioPlayer', AudioPlayer);
    app.component('PlatformSelector', PlatformSelector);
    app.component('MultiSelectDropdown', MultiSelectDropdown);
    app.component('FilesUploader', FilesUploader);

    // Generic Crud Component.
    app.component('generic-crud-component', GenericCrudComponent);

    const currentLocale = i18n.global.locale.value;
    const isRtl = currentLocale === 'ar';

    const toastOptions: PluginOptions = {
      position: POSITION.BOTTOM_CENTER,
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: true,
      rtl: isRtl,
    };

    app.use(Toast, toastOptions);
  }
};