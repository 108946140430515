<template>
  <div>
    <table v-if="items.length" class="min-w-full divide-y divide-gray-300 bg-white ring-1 ring-gray-300 rounded-md">
      <thead class="bg-gray-50">
        <tr>
          <th v-for="field in fields" :key="field" class="px-6 py-3 text-start text-sm font-semibold text-gray-500">
            <template v-if="!field.includes('_id')">{{ $t(`store.${field}`) }}</template>
          </th>
          <th v-if="canEdit || canDelete" class="px-8 py-4 text-start text-sm font-semibold text-gray-500">{{ $t("store.actions") }}</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <tr v-for="item in items" :key="item.id">
          <td v-for="field in fields" :key="field" class="px-6 py-4 text-sm text-gray-500 max-w-72 ">
            <template v-if="modelFields[field] === 'object'">
              {{ item[field]?.name || "-" }}
            </template>
            <template v-else-if="!field.includes('_id')">
              {{ item[field] }}
            </template>
          </td>
          <td v-if="canEdit || canDelete" class="flex gap-x-2 px-6 py-4 whitespace-nowrap text-start text-sm font-medium">
            <button v-if="canEdit" @click="$emit('edit', item)" class="text-indigo-600 hover:text-indigo-900 mr-2">{{ $t("store.edit") }}</button>
            <ButtonConfirmation v-if="canDelete" @confirm="$emit('delete', item)" class="hover:bg-indigo-100 p-1 rounded-md">{{ $t("store.delete") }}</ButtonConfirmation>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-else class="mt-4 text-sm text-gray-500">{{ $t("store.no_data_available") }}</p>
  </div>
</template>

<script lang="ts">
import { Item } from "@/types";
import { defineComponent, computed, PropType } from "vue";
import { JSONCrudModuleObject } from "@/interfaces/CrudModelTypes";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    modelFields: {
      type: Object as PropType<{ [key: string]: string | JSONCrudModuleObject }>,
      required: true,
    },
  },
  setup(props) {
    const fields = computed(() => {
      if (props.items.length === 0) return [];
      return Object.keys(props.items[0]).filter((key) => key !== "id");
    });

    return {
      fields,
      readonly: props.readonly,
      canEdit: props.canEdit,
      canDelete: props.canDelete,
    };
  },
});
</script>
