import { Module } from 'vuex';
import { Exam, ExamValidationRules } from '@/interfaces/Exam';
import { Duration } from '@/interfaces/Duration';
import { Grade } from '@/interfaces/Grade';
import { Skill } from '@/interfaces/Skill';
import { Round } from '@/interfaces/Round';
import { ExamType } from '@/interfaces/ExamType';
import { Level } from '@/interfaces/Level';
import { Platform } from '@/interfaces/Platform';
import { required, minLength, minValue, maxValue } from "@vuelidate/validators";
import { createInitialExamState, formatDateTime } from '@/utils/examUtils';
import eventBus from '@/utils/eventBus';
import examsService from '@/services/examsService';

export interface ExamState {
    exam: Exam;
    originalExam: Exam,
    grades: Grade[];
    skills: Skill[];
    rounds: Round[];
    examTypes: ExamType[];
    durations: Duration[];
    levels: Level[];
    isExamLoading: boolean;
    isExamFormLoadingPredefinedData: boolean;
    hasExamError: boolean;
    isExamSaving: boolean;
    selectedPlatform: Platform | null;
    validationRules$: ExamValidationRules;
    isFormValid: false,
}

const examModule: Module<ExamState, any> = {
    namespaced: true,
    state: (): ExamState => ({
        exam: createInitialExamState(),
        originalExam: createInitialExamState(),
        grades: [],
        skills: [],
        rounds: [],
        examTypes: [],
        durations: [],
        levels: [],
        isExamLoading: false,
        isExamFormLoadingPredefinedData: false,
        hasExamError: false,
        isExamSaving: false,
        selectedPlatform: null,
        validationRules$: {
            name_en: { required },
            name_ar: { required },
            duration_id: { required, minValue: minValue(1) },
            grade_id: {},
            level_id: {},
            skill_ids: { required, minLength: minLength(1) },
            round_id: { required, minValue: minValue(1) },
            start_date_time: { required },
            end_date_time: { required },
            exam_type_id: { required, minValue: minValue(1) },
            min_mark: { required, minValue: minValue(0), maxValue: maxValue(100) },
            max_mark: { required, minValue: minValue(0), maxValue: maxValue(100) },
            pass_mark: { required, minValue: minValue(0), maxValue: maxValue(100) },
        },
        isFormValid: false,
    }),
    getters: {
        isFieldVisible: (state: ExamState) => (fieldName: string): boolean => {
            const platformIdentifier = state.selectedPlatform?.identifier as keyof typeof platformFieldRules;

            if (!platformIdentifier) {
                return false;
            }

            const platformFieldRules = {
                IRA: ['grade_id'],
                ISA: ['level_id'],
                // Add other platform-specific rules here
            };

            return platformFieldRules[platformIdentifier]?.includes(fieldName) || false;
        },
    },
    mutations: {
        SET_EXAM(state: ExamState, exam: Exam) {
            state.exam = exam;
            state.originalExam = JSON.parse(JSON.stringify(exam));  // Clone the exam when it's first loaded
        },
        SET_GRADES(state: ExamState, grades: Grade[]) {
            state.grades = grades;
        },
        SET_SKILLS(state: ExamState, skills: Skill[]) {
            state.skills = skills;
        },
        SET_ROUNDS(state: ExamState, rounds: Round[]) {
            state.rounds = rounds;
        },
        SET_EXAM_TYPES(state: ExamState, examTypes: ExamType[]) {
            state.examTypes = examTypes;
        },
        SET_DURATIONS(state: ExamState, durations: Duration[]) {
            state.durations = durations;
        },
        SET_LEVELS(state: ExamState, levels: Level[]) {
            state.levels = levels;
        },
        SET_EXAM_LOADING(state: ExamState, isLoading: boolean) {
            state.isExamLoading = isLoading;
        },
        SET_EXAM_FORM_LOADING(state: ExamState, isLoading: boolean) {
            state.isExamFormLoadingPredefinedData = isLoading;
        },
        SET_HAS_EXAM_ERROR(state: ExamState, hasError: boolean) {
            state.hasExamError = hasError;
        },
        SET_EXAM_SAVING(state: ExamState, isSaving: boolean) {
            state.isExamSaving = isSaving;
        },
        SET_SELECTED_PLATFORM(state: ExamState, platform: Platform | null) {
            state.selectedPlatform = platform;
        },
        NOTIFY_SKILLS_CHANGED(state: ExamState, skillIds: number[]) {
            state.exam.skill_ids = skillIds;
            eventBus.emit('exam-skills-changed', skillIds);
        },
        NOTIFY_EXAM_CHANGED(state: ExamState, exam: Exam) {
            state.exam = exam;
            eventBus.emit('exam-changed', exam);
        }
    },
    actions: {
        async fetchExam({ commit }: any, examId: number) {
            commit('SET_EXAM_LOADING', true);
            let examResponse = null;
            try {
                examResponse = await examsService.getExamById(examId);
                commit('SET_EXAM', examResponse.data);
            } catch {
                console.error('Error fetching exam info', examResponse?.message);
                commit('SET_HAS_EXAMS_ERROR', true);
            } finally {
                commit('SET_EXAM_LOADING', false);
            }
        },

        async fetchForm({ commit, state }: any) {

            try {
                commit('SET_EXAM_FORM_LOADING', true);

                const examForm = await examsService.getExamForm(state.selectedPlatform?.id || 0);
                commit('SET_GRADES', examForm.grades);
                commit('SET_LEVELS', examForm.levels);
                commit('SET_SKILLS', examForm.skills);
                commit('SET_ROUNDS', examForm.rounds);
                commit('SET_DURATIONS', examForm.durations);
                commit('SET_EXAM_TYPES', examForm.examTypes);

            } catch (error) {
                console.error(error);
                commit('SET_HAS_EXAMS_ERROR', true);
            } finally {
                commit('SET_EXAM_FORM_LOADING', false);
            }
        },

        async saveExam({ state, commit }: any, { router, toast, t }: any) {
            commit('SET_EXAM_SAVING', true);

            try {
                const exam = state.exam;

                exam.name = exam.name_en;
                exam.start_date_time = formatDateTime(exam.start_date_time);
                exam.end_date_time = formatDateTime(exam.end_date_time);
                exam.platform_id = state.selectedPlatform?.id || 0;

                if (exam.id) {
                    await examsService.updateExam(exam);
                    toast.success(t("messages.exams.update.success"));
                } else {
                    await examsService.createExam(exam);
                    toast.success(t("messages.exams.add.success"));
                }

                await router.push("/exams");
            } catch (error) {
                toast.error(state.exam.id ? t("messages.exams.update.error") : t("messages.exams.add.error"));
                commit('SET_HAS_EXAMS_ERROR', true);
            } finally {
                commit('SET_EXAM_SAVING', false);
            }
        },

        initializeExam({ commit }: any) {
            commit('SET_EXAM', createInitialExamState());
        },

        onPlatformSelected({ commit, dispatch }: any, { platform, route }: any) {
            commit('SET_SELECTED_PLATFORM', platform);

            const examId = route.params.id;
            if (examId) {
                dispatch('fetchExam', Number(examId)).then(() => {
                    dispatch('fetchForm');
                });
            } else {
                dispatch('initializeExam').then(() => {
                    dispatch('fetchForm');
                });
            }
        },

        notifyExamSkillsChanged({ commit }, skillIds: number[]) {
            commit('NOTIFY_SKILLS_CHANGED', skillIds);
        },

        notifyExamChanged({ commit }, exam: Exam) {
            commit('NOTIFY_EXAM_CHANGED', exam);
        }
    },
};

export default examModule;