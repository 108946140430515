import apiClient from './apiService'

interface Api {
    getAll<T>(endpoint: string, params?: object): Promise<ApiResponse<T[]>>;
    getOne<T>(endpoint: string, id: number | string): Promise<ApiResponse<T>>;
    create<T>(endpoint: string, data: object): Promise<ApiResponse<T>>;
    update<T>(endpoint: string, id: number | string, data: object): Promise<ApiResponse<T>>;
    delete<T>(endpoint: string, id: number | string): Promise<ApiResponse<T>>;
}

// Generic CRUD operations
const api: Api = {
    async getAll<T>(endpoint: string, params?: object): Promise<ApiResponse<T[]>> {
        const response = await apiClient.get<ApiResponse<T[]>>(`/${endpoint}`, { params });
        return response.data;
    },

    async getOne<T>(endpoint: string, id: number | string): Promise<ApiResponse<T>> {
        const response = await apiClient.get<ApiResponse<T>>(`/${endpoint}/${id}`);
        return response.data;
    },

    async create<T>(endpoint: string, data: object): Promise<ApiResponse<T>> {
        const response = await apiClient.post<ApiResponse<T>>(`/${endpoint}`, data);
        return response.data;
    },

    async update<T>(endpoint: string, id: number | string, data: object): Promise<ApiResponse<T>> {
        const response = await apiClient.put<ApiResponse<T>>(`/${endpoint}/${id}`, data);
        return response.data;
    },

    async delete<T>(endpoint: string, id: number | string): Promise<ApiResponse<T>> {
        const response = await apiClient.delete<ApiResponse<T>>(`/${endpoint}/${id}`);
        return response.data;
    }
};

export default api;