import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './style.css'
import i18n from './i18n'
import globalComponents from './global-components'
import registerGlobalDirectives from './global-directives';
import store from './store'
import * as Sentry from "@sentry/vue";
import config from '@/config'

const app = createApp(App);

// Add global properties for $t and $tc i18n functions
app.config.globalProperties.$t = i18n.global.t
app.config.globalProperties.$tc = i18n.global.t

registerGlobalDirectives(app);

app.use(i18n);
app.use(store)
app.use(globalComponents);

Sentry.init({
    app,
    dsn: config.SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [config.BACKEND_URL_SENTRY],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.use(router).mount('#app');