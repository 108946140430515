import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import ar from '@/locales/ar.json'

const messages = {
    en,
    ar
}

// Load the language from local storage, default to 'en'
const savedLanguage = localStorage.getItem('language') || 'en'

export const i18n = createI18n({
    locale: savedLanguage, // set locale
    fallbackLocale: 'en', // set fallback locale
    messages,
    // Ensure legacy mode is not enabled
    legacy: false,
})

export default i18n