// TODO - to be moved.

export type FieldType = "string" | "number" | "boolean" | "object";
export interface JSONCrudModuleObject {
  [key: string]: any;
}
export interface FieldDescriptor {
  [fieldName: string]: FieldType | JSONCrudModuleObject;
}

const commonFields: FieldDescriptor = {
  id: "number",
  name_en: "string",
  name_ar: "string",
};

export const modelFieldsMap: { [key: string]: FieldDescriptor } = {
  "platforms": commonFields,

  "question-types": commonFields,
  "schools": commonFields,

  "skills": commonFields,
  "rounds": commonFields,
  "grades": commonFields,
  "levels": commonFields,
  "durations": {
    id: "number",
    number: "number",
    name_en: "string",
    name_ar: "string"
  },

  "cognitive-levels": commonFields,
  "difficulty-levels": commonFields,
  "learning-outcomes": {
    id: "number",
    name_en: "string",
    name_ar: "string",
    skill_id: "number",
    grade_id: "number",
    level_id: "number"
  },
  "depth-of-knowledges": commonFields,
};

export function adjustFieldsForPlatform(platformIdentifier: string, fieldsMap: { [key: string]: FieldDescriptor }) {
  const updatedFieldsMap = { ...fieldsMap };

  if (platformIdentifier === "IRA") {
    updatedFieldsMap["learning-outcomes"] = {
      id: "number",
      name_en: "string",
      name_ar: "string",
      skill_id: "number",
      grade_id: "number",
      skill: "object",
      grade: "object"
    };
  } else if (platformIdentifier === "ISA") {
    updatedFieldsMap["learning-outcomes"] = {
      id: "number",
      name_en: "string",
      name_ar: "string",
      skill_id: "number",
      level_id: "number",
      skill: "object",
      level: "object"
    };
  }

  return updatedFieldsMap;
}
