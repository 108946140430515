<template>
  <div class="relative">
    <!-- Dropdown button -->
    <button @click.prevent="toggleDropdown" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 ps-3 rtl:ps-8 text-start cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm hover:bg-indigo-100">
      <span class="block truncate">
        {{ selectedItems.length ? `${selectedItems.length} ${$t("common.selected")}` : placeholder }}
      </span>
      <span class="absolute inset-y-0 rtl:start-0 ltr:end-0 flex items-center pe-2 rtl:ps-2 pointer-events-none">
        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06 1.06L10 11.06l3.71-3.79a.75.75 0 111.08 1.06l-4.25 4.25a.75.75 0 01-1.06 0l-4.25-4.25a.75.75 0 010-1.06z" clip-rule="evenodd" />
        </svg>
      </span>
    </button>

    <!-- Dropdown menu -->
    <div v-if="dropdownOpen" @click.stop class="absolute mt-1 w-full rounded-lg ring-1 ring-gray-300 bg-white shadow-xl z-10 max-h-60 overflow-auto focus:outline-none text-sm">
      <template v-for="(groupItems, groupName) in groupedItems" :key="groupName">
        <div class="p-4 border-b border-gray-300">
          <div class="flex items-center" v-if="groupName">
            <input type="checkbox" :id="`group-checkbox-${groupName}`" :checked="isGroupSelected(groupItems)" @change="toggleGroupSelection(groupItems)" class="me-2 h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
            <label :for="`group-checkbox-${groupName}`" class="cursor-pointer font-semibold">{{ groupName }}</label>
          </div>
          <div class="ps-2 py-2">
            <template v-for="item in groupItems" :key="itemValue(item)">
              <div class="flex items-center py-1">
                <input type="checkbox" :id="`item-checkbox-${itemValue(item)}`" :value="itemValue(item)" v-model="selectedItems" @change="emitSelectionChange" class="me-2 h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
                <label :for="`item-checkbox-${itemValue(item)}`" class="cursor-pointer text-xs">
                  {{ itemLabel(item) }}
                </label>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "vue";

export default defineComponent({
  name: "MultiSelectDropdown",
  props: {
    modelValue: {
      type: Array as PropType<(number | string)[]>,
      required: true,
    },
    items: {
      type: Array as PropType<any[]>,
      required: true,
    },
    groupBy: {
      type: Function as PropType<(item: any) => string>,
      default: null, // Make groupBy optional
    },
    itemLabel: {
      type: Function as PropType<(item: any) => string>,
      required: true,
    },
    itemValue: {
      type: Function as PropType<(item: any) => number | string>,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select items",
    },
  },
  emits: ["update:modelValue"], // Declare the event emitted by the component
  setup(props, { emit }) {
    const dropdownOpen = ref(false);
    const selectedItems = ref<(number | string)[]>([...props.modelValue]); // Ensure consistent type

    const groupedItems = computed(() => {
      if (props.groupBy) {
        // Group items if groupBy function is provided
        return props.items.reduce((acc, item) => {
          const groupName = props.groupBy!(item);
          if (!acc[groupName]) {
            acc[groupName] = [];
          }
          acc[groupName].push(item);
          return acc;
        }, {} as Record<string, any[]>);
      } else {
        // No grouping, just return items in a single group
        return { "": props.items };
      }
    });

    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value;
    };

    const toggleGroupSelection = (groupItems: any[]) => {
      const groupValues = groupItems.map((item) => props.itemValue(item));
      const allSelected = groupValues.every((value) => selectedItems.value.includes(value));

      if (allSelected) {
        // Unselect all items in this group
        selectedItems.value = selectedItems.value.filter((value) => !groupValues.includes(value));
      } else {
        // Select all items in this group
        selectedItems.value = [...new Set([...selectedItems.value, ...groupValues])];
      }

      emit("update:modelValue", selectedItems.value); // Emit the updated value
    };

    const isGroupSelected = (groupItems: any[]) => {
      const groupValues = groupItems.map((item) => props.itemValue(item));
      return groupValues.every((value) => selectedItems.value.includes(value));
    };

    const emitSelectionChange = () => {
      emit("update:modelValue", selectedItems.value); // Emit the updated value
    };

    return {
      dropdownOpen,
      selectedItems,
      groupedItems,
      toggleDropdown,
      toggleGroupSelection,
      isGroupSelected,
      emitSelectionChange,
    };
  },
});
</script>
