import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-8 bg-white rounded-xl shadow-md" }
const _hoisted_2 = {
  key: 0,
  class: "flex-col space-y-4 mb-4"
}
const _hoisted_3 = { class: "text-xl" }
const _hoisted_4 = {
  key: 1,
  class: "flex items-center justify-center"
}
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "flex items-center mb-4" }
const _hoisted_8 = { class: "flex-auto" }
const _hoisted_9 = { class: "text-xl font-medium leading-6 text-gray-900" }
const _hoisted_10 = {
  key: 0,
  class: "flex gap-x-4"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "text-red-500 text-sm" }
const _hoisted_14 = { class: "bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl" }
const _hoisted_15 = { class: "text-lg font-semibold mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlatformSelector = _resolveComponent("PlatformSelector")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_MessageLayout = _resolveComponent("MessageLayout")!
  const _component_generic_list_component = _resolveComponent("generic-list-component")!
  const _component_generic_form_component = _resolveComponent("generic-form-component")!
  const _directive_can = _resolveDirective("can")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hasPlatform)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("platforms.select_message")), 1),
            _createVNode(_component_PlatformSelector, { onPlatformSelected: _ctx.onPlatformSelected }, null, 8, ["onPlatformSelected"])
          ])), [
            [_directive_can, 'com.ltm.permissions.platforms.view.platform']
          ])
        : _createCommentVNode("", true),
      (_ctx.isLoading && !_ctx.hasError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_Spinner, { showLoadingText: true })
          ]))
        : (_ctx.hasError && !_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_MessageLayout, {
              key: 2,
              message: _ctx.$t('store.error_fetching_data')
            }, null, 8, ["message"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (!_ctx.hasPlatform || _ctx.selectedPlatform)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.$t(`store.${_ctx.modelName}`)), 1),
                        _withDirectives(_createElementVNode("p", { class: "text-yellow-500 text-sm font-semibold mt-2" }, _toDisplayString(_ctx.$t("store.read_only_message")), 513), [
                          [_vShow, _ctx.readonly]
                        ])
                      ]),
                      (!_ctx.readonly && _ctx.canAdd)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("button", {
                              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addNew && _ctx.addNew(...args))),
                              class: "inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-base text-white shadow-sm hover:bg-indigo-500"
                            }, _toDisplayString(_ctx.$t("store.add_new")), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.canView)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_generic_list_component, {
                            items: _ctx.items,
                            "model-fields": _ctx.adjustedFieldsMap[_ctx.modelName],
                            onEdit: _ctx.editItem,
                            onDelete: _ctx.deleteItem,
                            readonly: _ctx.readonly,
                            canAdd: _ctx.canAdd,
                            canEdit: _ctx.canEdit,
                            canDelete: _ctx.canDelete
                          }, null, 8, ["items", "model-fields", "onEdit", "onDelete", "readonly", "canAdd", "canEdit", "canDelete"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t("store.no_permission")), 1)
                        ]))
                  ]))
                : _createCommentVNode("", true)
            ])),
      (_ctx.showModal)
        ? (_openBlock(), _createElementBlock("div", {
            key: 4,
            class: "fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.cancelForm && _ctx.cancelForm(...args)), ["self"]))
          }, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("h2", _hoisted_15, _toDisplayString(_ctx.currentItem?.id ? _ctx.$t("store.edit") : _ctx.$t("store.add_new")), 1),
              _createVNode(_component_generic_form_component, {
                item: _ctx.currentItem,
                "model-fields": _ctx.adjustedFieldsMap[_ctx.modelName],
                fields: _ctx.fields,
                onSubmit: _ctx.submitForm,
                onCancel: _ctx.cancelForm,
                "selected-platform": _ctx.selectedPlatform
              }, null, 8, ["item", "model-fields", "fields", "onSubmit", "onCancel", "selected-platform"])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}