<template>
  <div v-if="!isMobileOrTablet" class="h-screen flex flex-col">
    <Header />
    <main class="flex-1 sm:px-6 py-6 md:px-8 lg:px-12 xl:px-16 overflow-auto">
      <router-view></router-view>
    </main>
  </div>
  <BlockPage v-else />
</template>

<script>
import Header from "./Header.vue";
import BlockPage from "./BlockPage.vue";

export default {
  name: "MainLayout",
  components: {
    Header,
    BlockPage,
  },
  data() {
    return {
      isMobileOrTablet: false,
    };
  },
  mounted() {
    this.detectDevice();
    window.addEventListener("resize", this.detectDevice);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.detectDevice);
  },
  methods: {
    // Detect if the device is a mobile or tablet based on the screen width.
    detectDevice() {
      this.isMobileOrTablet = window.innerWidth <= 1024;
    },
  },
};
</script>

<style scoped></style>
