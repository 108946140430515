import apiClient from './apiService'
import { Permission, Role, SystemUser, SystemUserRequest } from '@/interfaces/User';

const endpoint = '/users';

export default {

  async getAllUsers(): Promise<ApiResponse<SystemUser[]>> {
    const response = await apiClient.get<ApiResponse<SystemUser[]>>(endpoint)
    return response.data
  },

  async getUserById(id: number): Promise<ApiResponse<SystemUser>> {
    const response = await apiClient.get<ApiResponse<SystemUser>>(`${endpoint}/${id}`)
    return response.data;
  },

  async createUser(user: SystemUserRequest): Promise<SystemUser> {
    const response = await apiClient.post<{ status: string; message: string; data: SystemUser }>(endpoint, user)
    return response.data.data
  },

  async updateUser(user: SystemUserRequest): Promise<SystemUser> {
    const response = await apiClient.put<{ status: string; message: string; data: SystemUser }>(`${endpoint}/${user.id}`, user)
    return response.data.data
  },

  async deleteUser(id: number): Promise<SystemUser> {
    const response = await apiClient.delete<{ status: string; message: string, data: SystemUser }>(`${endpoint}/${id}`)
    return response.data.data
  },

  async assignRole(userId: number, roleName: string): Promise<ApiResponse<SystemUser>> {
    const response = await apiClient.post<ApiResponse<SystemUser>>(`${endpoint}/${userId}/assign/role`, { role: roleName })
    return response.data
  },

  async removeRole(userId: number, roleName: string): Promise<ApiResponse<SystemUser>> {
    const response = await apiClient.post<ApiResponse<SystemUser>>(`${endpoint}/${userId}/remove/role`, { role: roleName })
    return response.data
  },

  async getAllRoles(): Promise<ApiResponse<Role[]>> {
    const response = await apiClient.get<ApiResponse<Role[]>>(`/roles/permissions`)
    return response.data
  },

  async getPermissions(roleName: string): Promise<ApiResponse<Permission[]>> {
    const response = await apiClient.get<ApiResponse<Permission[]>>(`roles/${roleName}/permissions`)
    return response.data
  },

  async changeUserPassword(userId: number, password: string, password_confirmation: string): Promise<ApiResponse<SystemUser>> {
    const response = await apiClient.put<ApiResponse<SystemUser>>(`${endpoint}/${userId}/change/password`, { password, password_confirmation })
    return response.data
  }

}