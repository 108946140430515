import api from './apiCrudService'
import { Platform, PlatformQueryParams } from '../interfaces/Platform'

export default {
    async getAllPlatforms(params?: PlatformQueryParams): Promise<Platform[]> {
        const response = await api.getAll<Platform>('platforms', params)
        return response.data
    },

    async getPlatformById(id: number): Promise<Platform> {
        const response = await api.getOne<Platform>('platforms', id)
        return response.data
    },

    async createPlatform(platform: Platform): Promise<Platform> {
        const response = await api.create<Platform>('platforms', platform)
        return response.data
    },

    async updatePlatform(platform: Platform): Promise<Platform> {
        const response = await api.update<Platform>('platforms', platform.id, platform)
        return response.data
    },

    async deletePlatform(id: number): Promise<Platform> {
        const response = await api.delete<Platform>('platforms', id)
        return response.data
    }
}