<template>
  <div class="exam-font flex-1 ring-1 ring-gray-300 rounded-md min-w-64 max-w-64 min-h-64 p-8 flex flex-col items-center justify-center">
    <!-- Initial State (Ready to Record) -->
    <div v-if="state === 'readyToRecord'" class="text-center">
      <button @click="startRecording" class="bg-white p-2 rounded-full hover:bg-gray-200">
        <img src="@/assets/icons/record.svg" class="w-16 h-16" />
      </button>
      <p class="text-gray-500 font-semibold mt-4">{{ $t("answers.types.audio_response.hint") }}</p>
    </div>

    <!-- Recording State -->
    <div v-if="state === 'recording'" class="w-full">
      <div class="flex flex-col items-center justify-center gap-y-4">
        <button @click="stopRecording" class="bg-red-500 p-4 rounded-full">
          <img src="@/assets/icons/stop.svg" class="w-8 h-8" />
        </button>
        <span class="text-3xl font-light">{{ formatTime(recordingTime) }}</span>
      </div>
    </div>

    <!-- Local Playback State (Recorded Audio) -->
    <div v-if="state === 'localPlayback'" class="w-full">
      <div class="flex justify-center items-center gap-x-4 mb-4">
        <!-- Play/Pause -->
        <button @click="togglePlayPause" class="bg-white p-2 rounded-full hover:bg-blue-200 disabled:opacity-25 disabled:cursor-not-allowed" :disabled="isUploading">
          <img v-if="isPlaying" src="@/assets/icons/pause.svg" class="w-8 h-8" />
          <img v-else src="@/assets/icons/play.svg" class="w-8 h-8" />
        </button>
        <!-- Retry -->
        <button @click="reRecord" class="bg-white p-2 rounded-full hover:bg-red-200 disabled:opacity-25 disabled:cursor-not-allowed" :disabled="isPlaying || isUploading">
          <img src="@/assets/icons/retry.svg" class="w-8 h-8" />
        </button>
        <!-- Confirm (Save) -->
        <button @click="confirmRecording" class="bg-white p-2 rounded-full hover:bg-green-200 disabled:opacity-25 disabled:cursor-not-allowed" :disabled="isPlaying || isUploading">
          <img src="@/assets/icons/disk-save.svg" class="w-8 h-8" />
        </button>
      </div>
      <!-- Progress Bar -->
      <input type="range" min="0" :max="duration" :value="currentTime" @input="seekAudio" class="w-full h-2 bg-gray-200 rounded-lg appearance-auto cursor-pointer" />
      <div class="flex justify-between text-sm text-gray-500">
        <span>{{ formatTime(currentTime) }}</span>
        <span>{{ formatTime(duration) }}</span>
        <!-- Correctly display the duration -->
      </div>
    </div>

    <!-- Remote Playback State (Saved Audio) -->
    <div v-if="state === 'remotePlayback'" class="w-full">
      <div class="flex justify-center items-center gap-x-4 mb-4">
        <!-- Play/Pause -->
        <button @click="togglePlayPause" class="bg-white p-2 rounded-full hover:bg-blue-200 disabled:opacity-25 disabled:cursor-not-allowed" :disabled="isUploading">
          <img v-if="isPlaying" src="@/assets/icons/pause.svg" class="w-8 h-8" />
          <img v-else src="@/assets/icons/play.svg" class="w-8 h-8" />
        </button>
        <!-- Retry (Allows Re-recording) -->
        <button v-if="!playModeOnly" @click="reRecord" class="bg-white p-2 rounded-full hover:bg-red-200 disabled:opacity-25 disabled:cursor-not-allowed" :disabled="isPlaying || isUploading">
          <img src="@/assets/icons/retry.svg" class="w-8 h-8" />
        </button>
      </div>
      <!-- Progress Bar -->
      <input type="range" min="0" :max="duration" :value="currentTime" @input="seekAudio" class="w-full h-2 bg-gray-200 rounded-lg appearance-auto cursor-pointer" />
      <div class="flex justify-between text-sm text-gray-500">
        <span>{{ formatTime(currentTime) }}</span>
        <span v-if="duration">{{ formatTime(duration) }}</span>
        <!-- Correctly display the duration -->
      </div>
    </div>

    <div v-if="isUploading" class="mt-4">
      <p class="text-gray-500">{{ $t("common.loading") }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import mediaService from "@/services/mediaService";

// Define the states
const state = ref<"readyToRecord" | "recording" | "localPlayback" | "remotePlayback">("remotePlayback"); // Start in 'remotePlayback' state

// Props
const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
  savedAudio: {
    type: String,
    default: null,
  },
  playModeOnly: {
    type: Boolean,
    default: false,
  },
});

// State management variables
const isRecording = ref(false);
const audioUrl = ref<string | null>(props.savedAudio);
const recordingTime = ref(0);
const isPlaying = ref(false);
const audioBlob = ref<Blob | null>(null);
const currentTime = ref(0);
const duration = ref(0);
const isUploading = ref(false);
const uploadSuccess = ref(!!props.savedAudio);

let mediaRecorder: MediaRecorder | null = null;
let audioChunks: Blob[] = [];
let recordingInterval: number | null = null;
let audio: HTMLAudioElement | null = null;
let stream: MediaStream | null = null;

const emit = defineEmits(["recordingComplete"]);

// Start recording
const startRecording = async () => {
  try {
    stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
    audioChunks = [];

    mediaRecorder.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    mediaRecorder.onstop = () => {
      audioBlob.value = new Blob(audioChunks, { type: "audio/webm" });
      audioUrl.value = URL.createObjectURL(audioBlob.value); // Set local audio URL
      duration.value = recordingTime.value;
      initAudio();
      state.value = "localPlayback"; // Switch to local playback state
    };

    mediaRecorder.start();
    isRecording.value = true;
    recordingTime.value = 0;
    state.value = "recording"; // Switch to recording state
    recordingInterval = window.setInterval(() => {
      recordingTime.value++;
      if (recordingTime.value >= 300) {
        stopRecording();
      }
    }, 1000);
  } catch (error) {
    console.error("Error starting recording:", error);
  }
};

// Stop recording
const stopRecording = () => {
  if (mediaRecorder) {
    mediaRecorder.stop();
    isRecording.value = false;
    if (recordingInterval) {
      clearInterval(recordingInterval);
    }
    stopMicrophoneStream();
  }
};

// Stop the microphone stream
const stopMicrophoneStream = () => {
  if (stream) {
    stream.getTracks().forEach((track) => track.stop());
    stream = null;
  }
};

// Initialize audio playback
const initAudio = () => {
  if (audioUrl.value) {
    // Create the audio element with the audio URL
    audio = new Audio(audioUrl.value);

    // Set preload to 'auto' to buffer the file in the background
    audio.preload = "metadata";

    // Explicitly load the audio (if needed)
    audio.load();

    // Add event listeners for metadata loading
    audio.addEventListener("loadedmetadata", () => {
      handleMetadataLoaded();
    });

    audio.addEventListener("canplaythrough", () => {
      handleMetadataLoaded();
    });

    // Other event listeners (playback controls, error handling, etc.)
    audio.addEventListener("ended", () => {
      isPlaying.value = false;
      currentTime.value = 0;
    });

    audio.addEventListener("timeupdate", () => {
      currentTime.value = audio?.currentTime || 0;
    });

    audio.addEventListener("play", () => {
      isPlaying.value = true;
    });

    audio.addEventListener("pause", () => {
      isPlaying.value = false;
    });

    audio.addEventListener("error", (e) => {
      console.error("Error loading audio:", e);
    });

    // Start the continuous polling for valid duration
    // pollForValidDuration();
  }
};

// Polling function to continuously check for a valid duration until it is retrieved
const pollForValidDuration = () => {
  const pollInterval = setInterval(() => {
    if (audio && !isNaN(audio.duration) && audio.duration !== Infinity) {
      duration.value = audio.duration; // Set valid duration
      // console.log("Duration loaded successfully:", duration.value);
      clearInterval(pollInterval); // Stop polling when valid duration is found
    } else {
      // console.warn("Duration not yet available, retrying...");
    }
  }, 500); // Poll every 500ms, continue indefinitely until duration is available
};

// Handle metadata loaded and ensure duration is fetched
const handleMetadataLoaded = () => {
  if (audio?.duration && !isNaN(audio.duration) && audio.duration !== Infinity) {
    duration.value = audio.duration; // Set valid duration
    // console.log("Duration loaded successfully:", duration.value);
  } else {
    // console.warn("Duration not yet available, retrying...");
    pollForValidDuration(); // Continue polling if duration is not valid
  }
};

// Toggle Play/Pause
const togglePlayPause = () => {
  if (audio) {
    if (isPlaying.value) {
      audio.pause();
    } else {
      audio.play();
    }
    isPlaying.value = !isPlaying.value;
  }
};

// Seek Audio
const seekAudio = (event: Event) => {
  if (audio) {
    const target = event.target as HTMLInputElement;
    audio.currentTime = Number(target.value);
    currentTime.value = audio.currentTime;
  }
};

// Re-record
const reRecord = () => {
  if (audio) {
    audio.pause();
    audio = null;
  }
  audioUrl.value = null;
  audioBlob.value = null;
  isPlaying.value = false;
  currentTime.value = 0;
  duration.value = 0;
  recordingTime.value = 0;
  uploadSuccess.value = false;
  state.value = "readyToRecord"; // Reset to ready-to-record state
  stopMicrophoneStream();
};

// Confirm and save recording
const confirmRecording = async () => {
  if (audioBlob.value) {
    isUploading.value = true;
    try {
      const file = new File([audioBlob.value], "audio.webm", { type: "audio/webm" });
      const response = await mediaService.uploadMedia(file, "exams/questions/" + props.question.id + "/audio_responses");

      if (response.status) {
        uploadSuccess.value = true;
        emit("recordingComplete", response.data.relative_path); // Emit saved path
        audioUrl.value = response.data.preview_url; // Update audio URL to saved audio
        state.value = "remotePlayback"; // Switch to remote playback state
        initAudio();
      } else {
        console.error("Upload failed:", response.message);
      }
    } catch (error) {
      console.error("Error uploading audio:", error);
    } finally {
      isUploading.value = false;
    }
  }
};

// Format time to MM:SS
const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

// On mount, initialize audio based on playModeOnly and savedAudio
onMounted(() => {
  if (props.playModeOnly && props.savedAudio) {
    // If playModeOnly is true and there's saved audio, allow playback only
    audioUrl.value = props.savedAudio;
    state.value = "remotePlayback";
    initAudio();
  } else if (!props.playModeOnly && props.savedAudio) {
    // If playModeOnly is false and saved audio exists, allow playback and recording
    audioUrl.value = props.savedAudio;
    state.value = "remotePlayback";
    initAudio();
  } else if (!props.playModeOnly && !props.savedAudio) {
    // If playModeOnly is false and there's no saved audio, allow recording
    state.value = "readyToRecord";
  } else {
    // If playModeOnly is true but there's no saved audio, disable recording and playback (or set default behavior)
    state.value = "readyToRecord"; // or set an appropriate fallback state
  }
});

// Clean up on unmount
onUnmounted(() => {
  if (recordingInterval) {
    clearInterval(recordingInterval);
  }
  if (audio) {
    audio.pause();
    audio = null;
  }
  stopMicrophoneStream();
});
</script>

<style scoped>
.exam-font {
  font-family: "Rubik", sans-serif;
}
</style>
