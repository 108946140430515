import { Module } from 'vuex';
import usersService from '@/services/usersService';
import { SystemUser, Role, Permission, SystemUserRequest } from '@/interfaces/User';

interface UsersState {
    users: SystemUser[];
    selectedUser: SystemUser | null;
    roles: Role[];
    permissions: Permission[];
    loading: boolean;
    error: string | null;
}

const usersModule: Module<UsersState, any> = {
    namespaced: true,
    state: {
        users: [],
        selectedUser: null,
        roles: [],
        permissions: [],
        loading: false,
        error: null,
    },
    mutations: {
        setUsers(state, users: SystemUser[]) {
            state.users = users;
        },
        setSelectedUser(state, user: SystemUser | null) {
            state.selectedUser = user;
        },
        setRoles(state, roles: Role[]) {
            state.roles = roles;
        },
        setPermissions(state, permissions: Permission[]) {
            state.permissions = permissions;
        },
        setLoading(state, loading: boolean) {
            state.loading = loading;
        },
        setError(state, error: string | null) {
            state.error = error;
        },
    },
    actions: {
        async fetchUsers({ commit }) {
            commit('setLoading', true);
            try {
                const response = await usersService.getAllUsers();
                commit('setUsers', response.data);
            } catch (error) {
                commit('setError', error || 'Failed to fetch users');
            } finally {
                commit('setLoading', false);
            }
        },
        async fetchUserById({ commit }, id: number) {
            commit('setLoading', true);
            try {
                const response = await usersService.getUserById(id);
                commit('setSelectedUser', response.data);
            } catch (error) {
                commit('setError', error || 'Failed to fetch user');
            } finally {
                commit('setLoading', false);
            }
        },
        async createUser({ commit, dispatch }, user: SystemUserRequest) {
            commit('setLoading', true);
            try {
                await usersService.createUser(user);
                dispatch('fetchUsers'); // Refresh the users list
            } catch (error) {
                commit('setError', error || 'Failed to create user');
            } finally {
                commit('setLoading', false);
            }
        },
        async updateUser({ commit, dispatch }, user: SystemUserRequest) {
            commit('setLoading', true);
            try {
                await usersService.updateUser(user);
                dispatch('fetchUsers'); // Refresh the users list
            } catch (error) {
                commit('setError', error || 'Failed to update user');
            } finally {
                commit('setLoading', false);
            }
        },
        async deleteUser({ commit, dispatch }, id: number) {
            commit('setLoading', true);
            try {
                await usersService.deleteUser(id);
                dispatch('fetchUsers'); // Refresh the users list
            } catch (error) {
                commit('setError', error || 'Failed to delete user');
            } finally {
                commit('setLoading', false);
            }
        },
        async assignRole({ commit, dispatch }, { userId, roleName }) {
            commit('setLoading', true);
            try {
                await usersService.assignRole(userId, roleName);
                dispatch('fetchUserById', userId); // Refresh the selected user
            } catch (error) {
                commit('setError', error || 'Failed to assign role');
            } finally {
                commit('setLoading', false);
            }
        },
        async removeRole({ commit, dispatch }, { userId, roleName }) {
            commit('setLoading', true);
            try {
                await usersService.removeRole(userId, roleName);
                dispatch('fetchUserById', userId); // Refresh the selected user
            } catch (error) {
                commit('setError', error || 'Failed to remove role');
            } finally {
                commit('setLoading', false);
            }
        },
        async fetchRoles({ commit }) {
            commit('setLoading', true);
            try {
                const response = await usersService.getAllRoles();
                commit('setRoles', response.data);
            } catch (error) {
                commit('setError', error || 'Failed to fetch roles');
            } finally {
                commit('setLoading', false);
            }
        },
        async fetchPermissions({ commit }, roleName: string) {
            commit('setLoading', true);
            try {
                const response = await usersService.getPermissions(roleName);
                commit('setPermissions', response.data);
            } catch (error) {
                commit('setError', error || 'Failed to fetch permissions');
            } finally {
                commit('setLoading', false);
            }
        },
        async changeUserPassword({ commit }, {
            userId,
            password,
            password_confirmation,
        }) {
            commit('setLoading', true);
            try {
                await usersService.changeUserPassword(userId, password, password_confirmation);
            } catch (error) {
                commit('setError', error || 'Failed to change password');
            } finally {
                commit('setLoading', false);
            }
        }
    },
    getters: {
        allUsers: (state) => state.users,
        selectedUser: (state) => state.selectedUser,
        allRoles: (state) => state.roles,
        allPermissions: (state) => state.permissions,
        isLoading: (state) => state.loading,
        hasError: (state) => !!state.error,
        errorMessage: (state) => state.error,
    },
};

export default usersModule;
