import apiClient from '@/services/apiService';

class MediaService {
  async uploadMedia(file: File, label: string): Promise<UploadResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('label', label);

    try {
      const response = await apiClient.post<UploadResponse>('/upload-media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    } catch (error: any) {
      console.error('Error uploading file:', error);
      return {
        status: false,
        message: error.response?.data?.message || 'An error occurred while uploading the file.',
        data: {
          success: false,
          file_name: '',
          relative_path: '',
          label: '',
          preview_url: ''
        }
      };
    }
  }
}

export default new MediaService();