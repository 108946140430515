import apiClient from './apiService'
import { User } from '@/interfaces/User';

const endpoint = '/user';

export default {

  async changePassword(current_password: string, password: string, password_confirmation: string): Promise<ApiResponse<User>> {
    const response = await apiClient.put<ApiResponse<User>>(`${endpoint}/change/password`, { current_password, password, password_confirmation })
    return response.data
  },

  async changeInformation(name: string): Promise<ApiResponse<User>> {
    const response = await apiClient.put<ApiResponse<User>>(`${endpoint}/change/information`, { name })
    return response.data
  },

}