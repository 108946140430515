<template>
  <div>
    <div class="p-8 bg-white rounded-xl shadow-md">
      <div v-if="hasPlatform" class="flex-col space-y-4 mb-4" v-can="'com.ltm.permissions.platforms.view.platform'">
        <p class="text-xl">{{ $t("platforms.select_message") }}</p>
        <PlatformSelector @platform-selected="onPlatformSelected" />
      </div>
      <div v-if="isLoading && !hasError" class="flex items-center justify-center">
        <Spinner :showLoadingText="true" />
      </div>
      <MessageLayout v-else-if="hasError && !isLoading" :message="$t('store.error_fetching_data')" />
      <div v-else>
        <div v-if="!hasPlatform || selectedPlatform">
          <div class="flex items-center mb-4">
            <div class="flex-auto">
              <h2 class="text-xl font-medium leading-6 text-gray-900">{{ $t(`store.${modelName}`) }}</h2>
              <p v-show="readonly" class="text-yellow-500 text-sm font-semibold mt-2">{{ $t("store.read_only_message") }}</p>
            </div>
            <div class="flex gap-x-4" v-if="!readonly && canAdd">
              <button @click="addNew" class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-base text-white shadow-sm hover:bg-indigo-500">{{ $t("store.add_new") }}</button>
            </div>
          </div>
          <div v-if="canView">
            <generic-list-component :items="items" :model-fields="adjustedFieldsMap[modelName]" @edit="editItem" @delete="deleteItem" :readonly="readonly":canAdd="canAdd" :canEdit="canEdit" :canDelete="canDelete" />
          </div>
          <div v-else>
            <p class="text-red-500 text-sm">{{ $t("store.no_permission") }}</p>
          </div>
        </div>
      </div>

      <!-- Modal for Add/Edit -->
      <div v-if="showModal" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" @click.self="cancelForm">
        <div class="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl">
          <h2 class="text-lg font-semibold mb-4">{{ currentItem?.id ? $t("store.edit") : $t("store.add_new") }}</h2>
          <generic-form-component :item="currentItem" :model-fields="adjustedFieldsMap[modelName]" :fields="fields" @submit="submitForm" @cancel="cancelForm" :selected-platform="selectedPlatform" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { usePermissions } from "@/composables/auth/usePermissions";
import { useToast } from "vue-toastification";

import GenericListComponent from "@/components/common/GenericListComponent.vue";
import GenericFormComponent from "@/components/common/GenericFormComponent.vue";
import api from "@/services/apiCrudService";

import { adjustFieldsForPlatform, FieldDescriptor, modelFieldsMap } from "@/interfaces/CrudModelTypes";

import { Item } from "@/types";
import { Platform } from "@/interfaces/Platform";

export default defineComponent({
  components: {
    GenericListComponent,
    GenericFormComponent,
  },
  props: {
    modelName: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    isPlatformDependant: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const toast = useToast();
    const { t } = useI18n();
  
    const { canView, canAdd, canEdit, canDelete } = usePermissions(props.modelName);
    const items = ref<Item[]>([]);
    const currentItem = ref<Item | null>(null);
    const showForm = ref(false);
    const showModal = ref(false);

    const isLoading = ref(false);
    const hasError = ref(false);

    const selectedPlatform = ref<Platform | null>(null);

    const adjustedFieldsMap = computed<{ [key: string]: FieldDescriptor }>(() => {
      return adjustFieldsForPlatform(selectedPlatform.value?.identifier || "", modelFieldsMap);
    });

    const fields = computed(() => {
      const fieldsForModel = adjustedFieldsMap.value[props.modelName];

      // Get the keys of the fieldsForModel object
      const fieldKeys = Object.keys(fieldsForModel);

      if (items.value.length === 0) return fieldKeys.filter((key) => key !== "id");

      return Object.keys(items.value[0])
        .filter((key) => fieldKeys.includes(key))
        .filter((key) => key !== "id");
    });

    const modelFields = computed(() => {
      const fields = adjustedFieldsMap.value[props.modelName];
      if (fields) {
        return fields;
      } else {
        console.warn(`Model name ${props.modelName} is not valid.`);
        return [];
      }
    });

    const onPlatformSelected = (platform: Platform) => {
      selectedPlatform.value = platform;
      fetchItems();
    };

    const fetchItems = async () => {
      isLoading.value = true;
      try {
        const response = await api.getAll<Item[]>(props.modelName, {
          filter: {
            platform_id: selectedPlatform.value?.id,
          },
        });
        const filteredItems = response.data.map((item: any) => {
          const filteredItem: any = {};

          // Iterate over the keys of modelFields.value
          Object.keys(modelFields.value).forEach((field) => {
            filteredItem[field] = item[field];
          });

          return filteredItem;
        });

        items.value = filteredItems as Item[];
      } catch (error) {
        toast.error(t(`messages.${props.modelName}.fetch.error`));
        hasError.value = true;
      } finally {
        isLoading.value = false;
      }
    };

    const addNew = () => {
      currentItem.value = initializeFormFields();
      showForm.value = true;
      showModal.value = true;
    };

    const editItem = (item: Item) => {
      currentItem.value = { ...item };
      showForm.value = true;
      showModal.value = true;
    };

    const initializeFormFields = () => {
      const initialItem: any = {};
      Object.keys(modelFields.value).forEach((field) => {
        initialItem[field] = null;
      });
      return initialItem;
    };

    const deleteItem = async (item: Item) => {
      try {
        await api.delete(props.modelName, item.id);
        await fetchItems();
        toast.success(t(`messages.${props.modelName}.delete.success`));
      } catch (error) {
        console.error(error);
        toast.error(t(`messages.${props.modelName}.delete.error`));
      }
    };

    const submitForm = async (formData: Item) => {
      try {
        formData.name = formData.name_en;
        if (props.isPlatformDependant && selectedPlatform.value) {
          formData.platform_id = selectedPlatform.value.id;
        }
        if (formData.id) {
          await api.update(props.modelName, formData.id, formData);
        } else {
          await api.create(props.modelName, formData);
        }
        showForm.value = false;
        showModal.value = false;
        await fetchItems();
        toast.success(t(`messages.${props.modelName}.${formData.id ? "update" : "add"}.success`));
      } catch (error) {
        toast.error(t(`messages.${props.modelName}.${formData.id ? "update" : "add"}.error`));
      }
    };

    const cancelForm = () => {
      showForm.value = false;
      showModal.value = false;
      currentItem.value = null;
    };

    onMounted(() => {
      if (!props.isPlatformDependant) {
        fetchItems();
      }
    });

    return {
      items,
      currentItem,
      showForm,
      showModal,
      fields,
      readonly: props.readonly,
      isLoading,
      hasError,
      addNew,
      editItem,
      deleteItem,
      submitForm,
      cancelForm,

      selectedPlatform,
      onPlatformSelected,

      adjustedFieldsMap,

      hasPlatform: props.isPlatformDependant,

      canAdd: props.canAdd && canAdd,
      canEdit: props.canEdit && canEdit,
      canDelete: props.canDelete && canDelete,
      canView,
    };
  },
});
</script>
