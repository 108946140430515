<!-- This is a common component that will be used to display messages in the application. -->
<template>
  <div>
    <p class="text-gray-500 text-lg">{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "MessageLayout",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {
      message: props.message,
    };
  },
};
</script>

<style scoped></style>
