import { DirectiveBinding } from 'vue';
import store from '@/store';

const hasRoleDirective = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        const roles = store.getters['currentUser/currentUser']?.roles.map((r: any) => r.role);
        const requiredRole = binding.value;

        if (!roles || !roles.includes(requiredRole)) {
            el.parentNode?.removeChild(el);  // Remove the element from the DOM
        }
    }
};

export default hasRoleDirective;
