import { ActionContext } from 'vuex';
import apiService from '@/services/apiCrudService';
import { CrudState, Item } from '@/types';

type Context = ActionContext<CrudState, any>;

const crudModule = {
  namespaced: true,
  state: (): CrudState => ({
    items: [],
    currentItem: null,
    loading: false,
    error: null
  }),
  mutations: {
    setItems(state: CrudState, items: Item[]) {
      state.items = items;
    },
    setCurrentItem(state: CrudState, item: Item | null) {
      state.currentItem = item;
    },
    setLoading(state: CrudState, loading: boolean) {
      state.loading = loading;
    },
    setError(state: CrudState, error: string | null) {
      state.error = error;
    }
  },
  actions: {
    async fetchItems({ commit }: Context, modelName: string) {
      commit('setLoading', true);
      try {
        const items = await apiService.getAll(modelName);
        commit('setItems', items);
      } catch (error) {
        commit('setError', 'Failed to fetch items');
      } finally {
        commit('setLoading', false);
      }
    },
    async fetchItem({ commit }: Context, { modelName, id }: { modelName: string; id: number | string }) {
      commit('setLoading', true);
      try {
        const item = await apiService.getOne(modelName, id);
        commit('setCurrentItem', item);
      } catch (error) {
        commit('setError', 'Failed to fetch item');
      } finally {
        commit('setLoading', false);
      }
    },
    async createItem({ commit }: Context, { modelName, data }: { modelName: string; data: any }) {
      commit('setLoading', true);
      try {
        const newItem = await apiService.create(modelName, data);
        commit('setCurrentItem', newItem);
      } catch (error) {
        commit('setError', 'Failed to create item');
      } finally {
        commit('setLoading', false);
      }
    },
    async updateItem({ commit }: Context, { modelName, id, data }: { modelName: string; id: number | string; data: any }) {
      commit('setLoading', true);
      try {
        const updatedItem = await apiService.update(modelName, id, data);
        commit('setCurrentItem', updatedItem);
      } catch (error) {
        commit('setError', 'Failed to update item');
      } finally {
        commit('setLoading', false);
      }
    },
    async deleteItem({ commit }: Context, { modelName, id }: { modelName: string; id: number | string }) {
      commit('setLoading', true);
      try {
        await apiService.delete(modelName, id);
        commit('setCurrentItem', null);
      } catch (error) {
        commit('setError', 'Failed to delete item');
      } finally {
        commit('setLoading', false);
      }
    }
  }
};

export default crudModule;