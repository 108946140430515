import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import authService from "@/services/authService";

export function useCurrentUser() {
  const router = useRouter();
  const store = useStore();
  const toast = useToast();
  const { t } = useI18n();

  const user = computed(() => store.getters['currentUser/currentUser']);
  const isAuthenticated = computed(() => store.getters['currentUser/isAuthenticated']);

  const changePassword = async (currentPassword: string, newPassword: string, confirmPassword: string) => {
    try {
      await store.dispatch('currentUser/changePassword', {
        currentPassword,
        newPassword,
        confirmPassword,
      });
    } catch (errorMessage) {
      toast.error(errorMessage || 'Failed to change password');
    }
  };

  const changeInformation = async (name: string) => {
    try {
      await store.dispatch('currentUser/changeInformation', name);
    } catch (errorMessage) {
      toast.error(errorMessage || 'Failed to update information');
    }
  };

  const logoutUser = () => {
    authService.logout();
    router.push({ name: 'Login' });
  };

  watch(isAuthenticated, (newIsAuthenticated) => {
    if (!newIsAuthenticated) {
      logoutUser();
    }
  });

  return {
    user,
    isAuthenticated,
    changePassword,
    changeInformation,
    logoutUser
  };
}

export default useCurrentUser;
