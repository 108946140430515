import { Module } from 'vuex';
import examsService from '@/services/examsService';
import { Exam, ExamStatus, ExamStudent, ExamStudentQueryParams, StudentExamStatus } from '@/interfaces/Exam';

interface FilterOption {
    id: number;
    name: string;
}

interface FilterOptions {
    exam_status: FilterOption[];
    student_exam_status: FilterOption[];
    school: FilterOption[];
    school_class: FilterOption[];
    [key: string]: FilterOption[];
}

export interface ExamStudentsState {
    exam: Exam | null;
    examStudents: ExamStudent[];
    selectedExamStudents: number[];
    selectedStudents: number[];
    isExamLoading: boolean;
    isExamStudentsLoading: boolean;
    isAllExamStudentsSelected: boolean;
    hasExamError: boolean;
    hasExamStudentsError: boolean;
    params: ExamStudentQueryParams;
    filterOptions: FilterOptions;
    meta: PaginationMeta;
    showFilterDropdown: boolean;
    selectedFilterCategories: string[];
    selectedFilters: Record<string, number[]>;
}

const relatedFieldMapping: Record<string, string> = {
    school: 'student.school_id',
    school_class: 'student.school_class_id',
    // Add more mappings if needed like other nested fields.
};

const examStudentsModule: Module<ExamStudentsState, any> = {
    namespaced: true,

    state: (): ExamStudentsState => ({
        exam: null,
        examStudents: [],
        selectedExamStudents : [],
        selectedStudents: [],
        isExamLoading: false,
        isExamStudentsLoading: false,
        isAllExamStudentsSelected: false,
        hasExamError: false,
        hasExamStudentsError: false,
        params: {
            sort: 'id',
            order: 'desc',
            page: 1,
            search: '',
            filter: {},
        },
        filterOptions: {
            exam_status: [],
            student_exam_status: [],
            school: [],
            school_class: [],
        },
        meta: {
            current_page: 0,
            last_page: 0,
            per_page: 0,
            total: 0,
            first_page_number: 1,
            last_page_number: 0,
            next_page_number: null,
            prev_page_number: null,
            pages: [],
        },
        showFilterDropdown: false,
        selectedFilterCategories: [],
        selectedFilters: {
            exam_status: [],
            student_exam_status: [],
            school: [],
            school_class: [],
        },
    }),

    getters: {
        examStudentsTableHeaders: () => [
            { key: "name", label: "exams.students.preview.table.header.student_name" },
            { key: "start_end_time", label: "exams.students.preview.table.header.start_end_time" },
            { key: "school", label: "exams.students.preview.table.header.school" },
            { key: "school_class", label: "exams.students.preview.table.header.school_class" },
            { key: "exam_status", label: "exams.students.preview.table.header.exam_status" },
            { key: "student_status", label: "exams.students.preview.table.header.student_status" },
            { key: "total_mark", label: "exams.students.preview.table.header.total_mark" },
            { key: "pass_mark", label: "exams.students.preview.table.header.pass_mark" },
            { key: "total", label: "exams.students.preview.table.header.total" },
            { key: "actions", label: "exams.students.preview.table.header.actions" },
        ],
        commonExamStudentsTableHeadersClass: (): string => "p-4 text-start text-sm font-semibold text-gray-500",
        filterCategoriesOptions: (): Record<string, string> => ({
            exam_status: 'exams.students.preview.table.filters.exam_status',
            student_exam_status: 'exams.students.preview.table.filters.student_exam_status',
            school: 'exams.students.preview.table.filters.schools',
            school_class: 'exams.students.preview.table.filters.school_classes',
        }),
        availableFiltersZone: (state): Record<string, any> => {
            return state.selectedFilterCategories.reduce((acc, key) => {
                acc[key] = state.filterOptions[`${key}` as keyof typeof state.filterOptions];
                return acc;
            }, {} as Record<string, any>);
        },
        isExamStudentSelected: (state) => (examStudentId: number) => {
            return state.selectedExamStudents.includes(examStudentId);
        },
        areAllExamStudentsSelected: (state) => {
            return state.examStudents.length > 0 && state.selectedExamStudents.length === state.examStudents.length;
        },
    },

    mutations: {
        SET_EXAM(state, exam: Exam | null) {
            state.exam = exam;
        },
        SET_EXAM_STUDENTS(state, students: ExamStudent[]) {
            state.examStudents = students;
        },
        SET_EXAM_LOADING(state, value: boolean) {
            state.isExamLoading = value;
        },
        SET_EXAM_STUDENTS_LOADING(state, value: boolean) {
            state.isExamStudentsLoading = value;
        },
        SET_EXAM_ERROR(state, value: boolean) {
            state.hasExamError = value;
        },
        SET_EXAM_STUDENTS_ERROR(state, value: boolean) {
            state.hasExamStudentsError = value;
        },
        SET_PARAMS(state, params: Partial<ExamStudentsState['params']>) {
            state.params = { ...state.params, ...params };
        },
        SET_FILTER_OPTIONS(state, options: ExamStudentsState['filterOptions']) {
            state.filterOptions = options;
        },
        SET_PAGINATION_META(state, meta: PaginationMeta) {
            state.meta = meta;
        },
        SET_SHOW_FILTER_DROPDOWN(state, value: boolean) {
            state.showFilterDropdown = value;
        },
        SET_SELECTED_FILTER_CATEGORIES(state, categories: string[]) {
            state.selectedFilterCategories = categories;
        },
        SET_SELECTED_FILTERS(state, filters: Record<string, number[]>) {
            state.selectedFilters = filters;
        },
        TOGGLE_SELECT_ALL(state) {
            if (state.selectedExamStudents.length === state.examStudents.length) {
                // Deselect all
                state.selectedExamStudents = [];
            } else {
                // Select all
                state.selectedExamStudents = state.examStudents.map(item => item.id);
            }
            // After selecting/deselecting all, check if all students are selected
            state.isAllExamStudentsSelected = state.selectedExamStudents.length === state.examStudents.length;
        },
        TOGGLE_SELECT_EXAM_STUDENT(state, examStudentId: number) {
            const index = state.selectedExamStudents.indexOf(examStudentId);
            if (index > -1) {
                // Deselect the student if already selected
                state.selectedExamStudents.splice(index, 1);
            } else {
                // Select the student if not already selected
                state.selectedExamStudents.push(examStudentId);
            }
            // After toggling a student, update the select-all status
            state.isAllExamStudentsSelected = state.selectedExamStudents.length === state.examStudents.length;
        },
        SET_SELECTED_EXAM_STUDENTS(state, examStudentIds: number[]) {
            state.selectedExamStudents = examStudentIds; // Sets the selected student IDs
        },
        SET_SELECTED_STUDENTS(state) {
            state.selectedStudents = state.examStudents.filter((student) => state.selectedExamStudents.includes(student.id)).map((student) => student.student_id);
        }
    },

    actions: {
        async fetchExamInfo({ commit }, id: number) {
            commit('SET_EXAM_LOADING', true);
            commit('SET_EXAM_ERROR', false);
            try {
                const examResponse = await examsService.getExamById(id);
                commit('SET_EXAM', examResponse.data);
            } catch (err) {
                console.error('Error fetching exam info', err);
                commit('SET_EXAM_ERROR', true);
            } finally {
                commit('SET_EXAM_LOADING', false);
            }
        },

        async fetchExamAssignedStudents({ commit, state }) {
            if (!state.exam) {
                console.error('Exam not loaded. Cannot fetch assigned students.');
                return;
            }
            commit('SET_EXAM_STUDENTS_LOADING', true);
            commit('SET_EXAM_STUDENTS_ERROR', false);
            try {
                const examAssignedStudentsResponse = await examsService.getAssignedStudents(state.exam.id, state.params);
                commit('SET_EXAM_STUDENTS', examAssignedStudentsResponse.data);
                commit('SET_FILTER_OPTIONS', examAssignedStudentsResponse.filters);
                commit('SET_PAGINATION_META', examAssignedStudentsResponse.meta);
                commit('SET_SELECTED_EXAM_STUDENTS', []);
            } catch (err) {
                console.error('Error fetching exam students', err);
                commit('SET_EXAM_STUDENTS_ERROR', true);
            } finally {
                commit('SET_EXAM_STUDENTS_LOADING', false);
            }
        },

        async loadExamAndStudents({ dispatch }, examId: number) {
            await dispatch('fetchExamInfo', examId);
            await dispatch('fetchExamAssignedStudents');
        },

        updateParams({ commit, dispatch }, params: Partial<ExamStudentsState['params']>) {
            commit('SET_PARAMS', params);
            dispatch('fetchExamAssignedStudents');
        },

        toggleFilterDropdown({ commit, state }) {
            commit('SET_SHOW_FILTER_DROPDOWN', !state.showFilterDropdown);
        },

        updateSelectedFilterCategories({ commit, state }, category: string) {
            const updatedCategories = state.selectedFilterCategories.includes(category)
                ? state.selectedFilterCategories.filter(c => c !== category)
                : [...state.selectedFilterCategories, category];
            commit('SET_SELECTED_FILTER_CATEGORIES', updatedCategories);

            if (!updatedCategories.includes(category)) {
                const updatedFilters = { ...state.selectedFilters };
                delete updatedFilters[category];
                commit('SET_SELECTED_FILTERS', updatedFilters);
            }
        },

        updateFilter({ commit, state, dispatch }, { key, value }: { key: string; value: number }) {
            const updatedFilters = { ...state.selectedFilters };
    
            if (!updatedFilters[key]) {
                updatedFilters[key] = [];
            }
    
            const index = updatedFilters[key].indexOf(value);
            if (index > -1) {
                updatedFilters[key].splice(index, 1);
            } else {
                updatedFilters[key].push(value);
            }
    
            commit('SET_SELECTED_FILTERS', updatedFilters);
    
            const filterParams: Record<string, string> = {};
            Object.entries(updatedFilters).forEach(([filterKey, filterValues]) => {
                if (filterValues.length > 0) {
                    const filterField = relatedFieldMapping[filterKey] || `${filterKey}_id`;
                    filterParams[filterField] = filterValues.join(',');
                }
            });
    
            dispatch('updateParams', { filter: filterParams, page: 1 });
        },

        clearFilterCategories({ commit, dispatch }) {
            commit('SET_SELECTED_FILTER_CATEGORIES', []);
            commit('SET_SELECTED_FILTERS', {});
            commit('SET_SHOW_FILTER_DROPDOWN', false);
            dispatch('updateParams', { filter: {}, page: 1 });
        },

        clearCategoryFilter({ commit, state, dispatch }, key: string) {
            const updatedFilters = { ...state.selectedFilters };
            delete updatedFilters[key];
            commit('SET_SELECTED_FILTERS', updatedFilters);

            const updatedCategories = state.selectedFilterCategories.filter(c => c !== key);
            commit('SET_SELECTED_FILTER_CATEGORIES', updatedCategories);

            dispatch('updateParams', { filter: {}, page: 1 });
        },

        setSearch({ dispatch }, search: string) {
            dispatch('updateParams', { search, page: 1 });
        },

        toggleSelectAll({ commit }) {
            commit('TOGGLE_SELECT_ALL');
        },

        toggleSelectExamStudent({ commit }, examStudentId: number) {
            commit('TOGGLE_SELECT_EXAM_STUDENT', examStudentId);
        },

        updateAllSelected({ commit, state }) {
            if (state.selectedExamStudents.length === state.examStudents.length) {
                commit('SET_ALL_SELECTED', true);
            } else {
                commit('SET_ALL_SELECTED', false);
            }
        },

        openAssignationModal({ commit }) {
            commit('SET_SELECTED_STUDENTS'); // Set the selected students
        },
    },
};

export default examStudentsModule;