import { Module } from 'vuex';
import { User } from '@/interfaces/User';
import userService from '@/services/userService';

interface CurrentUserState {
    currentUser: User | null;
}

const currentUserState: Module<CurrentUserState, any> = {
    namespaced: true,
    state: {
        currentUser: null,
    },
    mutations: {
        setCurrentUser(state, user: User | null) {
            state.currentUser = user;
        },
    },
    actions: {
        updateUser({ commit }, user: User | null) {
            commit('setCurrentUser', user);
        },

        async changePassword({ commit }, { currentPassword, newPassword, confirmPassword }) {
            try {
                const response = await userService.changePassword(currentPassword, newPassword, confirmPassword);
                commit('setCurrentUser', response.data); // Assuming the API returns updated user data
                return response;
            } catch (error) {
                console.error('Error changing password:', error);
                throw error;
            }
        },

        async changeInformation({ commit }, name: string) {
            try {
                const response = await userService.changeInformation(name);
                commit('setCurrentUser', response.data); // Assuming the API returns updated user data
                return response;
            } catch (error) {
                console.error('Error updating information:', error);
                throw error;
            }
        }

    },
    getters: {
        currentUser: (state) => state.currentUser,
        isAuthenticated: (state) => !!state.currentUser,
        /**
         * Check if the user has a specific permission if needed
         */
        can: (state) => (permission: string) => {
            return state.currentUser?.permissions.some(p => p.permission === permission) || false;
        },
        /**
         * Check if the user has a specific role if needed
         */
        hasRole: (state) => (role: string) => {
            return state.currentUser?.roles.some(r => r.role === role) || false;
        }
    }
};

export default currentUserState;
