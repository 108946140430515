// store/index.ts
import { createStore } from 'vuex';
import crudModule from '@/store/modules/crudModule';
import usersModule from '@/store/modules/usersModule';
import platformsModule from './modules/platformsModule';
import examModule from '@/store/modules/examModule';
import examStudentsModule from '@/store/modules/examStudentsModule';
import currentUserModule from '@/store/modules/currentUserModule';

const store = createStore({
  modules: {
    currentUser: currentUserModule,
    usersManagement: usersModule,
    platforms: platformsModule,
    crud: crudModule,
    examForm: examModule,
    assignedStudents: examStudentsModule
  },
});

export default store;
